<template>
  <HelloWorld
    :role="role"
    :type="type"
    :activeLocation="activeLocation"
    :installation="installation"
  />
</template>

<script>
import HelloWorld from "./components/HelloWorld.vue";
import { GHL } from "./ghl";

export default {
  name: "App",
  components: {
    HelloWorld,
  },
  data() {
    return {
      activeLocation: "",
      role: "",
      type: "",
      installation: undefined,
    };
  },
  async mounted() {
    const data = await GHL.getInstance().getUserData();
    console.log("user-details", data);
    this.activeLocation = data.activeLocation;
    this.role = data.role;
    this.type = data.type;
    const installation = await GHL.getInstance().getInstalationInfo();

    if (!installation.locationId) return;
    this.installation = installation;
  },
};
</script>

<style>
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

:root {
  --fk-color-primary: #72dfff;
  --fk-color-button: black;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: white;
  background-color: #1f3745;
}
</style>
